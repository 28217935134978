import { AiOutlineHome } from "react-icons/ai";
import { PiHospital } from "react-icons/pi";
import { UserGroupIcon } from "@heroicons/react/24/outline";

import { permissions } from "./permissionsList";

export const navItemsIconMapping = {
    'homepage': <AiOutlineHome className="h-5 w-5" />,
    'tcm_dashboard': <PiHospital className="h-5 w-5" />,
    'patients': <UserGroupIcon className="h-5 w-5" />
};

export const navItems = [{
    appName: "Homepage",
    configName: "homepage",
    pathName: "/homepage",
    permissions: [...Object.values(permissions.homepage)]
}, {
    appName: "TCM Dashboard",
    configName: "tcm_dashboard",
    pathName: "/tcm-dashboard",
    permissions: [...Object.values(permissions.tcm)]
}];

export const KEYS = {
  	ACCESS_TOKEN: "accessToken",
};
