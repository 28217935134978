import toast from "react-hot-toast";

const { toastConfigs } = require("configs/appConfigs");

export const toastNotify = (message = "Sample message", type = "success", className) => {
    return toast((t) => 
        <div className={`font-semibold flex justify-between items-start gap-5 ${className || "text-xs"}`}>
            {message}
            <div className="mr-[-8px]">
                <i className="clinicus-close cursor-pointer" onClick={() => toast.dismiss(t.id)}/>
            </div>
        </div>, toastConfigs.defaults[type || "success"]
    );
}
